import React, { createRef, RefObject } from "react";
import { connect } from "react-redux";
import { AppState, AppDispatch } from "../../../core/store";
import {
  AboutUsStateProps,
  AboutUsDispatchProps,
  AboutUsProps,
  AboutUsState,
} from "./AboutUs.types";
import "./AboutUs.style.scss";
import ContentBox from "../../shared/ContentBox/ContentBox";
import Tile from "../../shared/Tile/Tile";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { fetchFromApi } from "../../../core/utils";
import Arrows from "../../shared/Arrows/Arrows";
import ScrollableList from "../../shared/ScrollableList/ScrollableList";
import ReactHtmlParser from "react-html-parser";

const mapStateToProps = (state: AppState): AboutUsStateProps => ({});

const mapDispatchToProps = (
  dispatch: AppDispatch
): AboutUsDispatchProps => ({});

const connector = connect(mapStateToProps, mapDispatchToProps);

class AboutUs extends React.Component<AboutUsProps, AboutUsState> {
  public state: AboutUsState = {
    aboutUsSingleton: undefined,
    teamMembers: [],
  };
  public teamRef: React.RefObject<HTMLDivElement>;
  public constructor(props: AboutUsProps) {
    super(props);
    this.teamRef = createRef();
  }

  public componentDidMount() {
    this.fetchAboutUsSingleton();
    this.fetchTeamMembers();
  }

  public componentDidUpdate(oldProps: AboutUsProps, oldState: AboutUsState) {}

  public fetchAboutUsSingleton = async () => {
    const aboutUsSingleton = await fetchFromApi<AboutUsSingleton, "singletons">(
      "singletons",
      "aboutUs"
    );

    this.setState({ aboutUsSingleton });
  };

  public fetchTeamMembers = async () => {
    const teamMembers = await fetchFromApi<TeamMember, "collections">(
      "collections",
      "team"
    );

    this.setState({ teamMembers });
  };

  public handleArrowLeftClick = () => {
    const teamInner = this.teamRef.current;
    teamInner?.scrollBy({ left: -220, behavior: "smooth" });
  };

  public handleArrowRightClick = () => {
    const teamInner = this.teamRef.current;
    teamInner?.scrollBy({ left: 220, behavior: "smooth" });
  };

  public render(): JSX.Element {
    const {} = this.props;
    const { teamMembers, aboutUsSingleton } = this.state;

    return (
      <div className={"aboutus"}>
        <ContentBox
          imagePath={aboutUsSingleton?.backgroundImage?.path}
          sections={[
            {
              header: "O nas",
              wysiwygable: true,
              headerInContent: "Misja",
              headerInContentStyle: { maxWidth: "552px" },
              item: (
                <div className="about-us-text-box">
                  {aboutUsSingleton &&
                    aboutUsSingleton.missionContent !== null &&
                    ReactHtmlParser(aboutUsSingleton.missionContent)}
                </div>
              ),
            },
            {
              header: "O projekcie",
              wysiwygable: true,
              headerInContent: "O projekcie",
              headerInContentStyle: { maxWidth: "552px" },
              isHidden:
                !aboutUsSingleton ||
                !aboutUsSingleton.aboutProject ||
                !aboutUsSingleton.aboutProject.length,
              item: (
                <div className="about-us-text-box">
                  {aboutUsSingleton &&
                    aboutUsSingleton.aboutProject !== undefined &&
                    aboutUsSingleton.aboutProject !== null &&
                    ReactHtmlParser(aboutUsSingleton.aboutProject)}
                </div>
              ),
            },
            {
              header: "Cele",
              wysiwygable: true,
              headerInContent: "Cele",
              isHidden:
                !aboutUsSingleton ||
                !aboutUsSingleton.goalsContent ||
                !aboutUsSingleton.goalsContent.length,
              headerInContentStyle: { maxWidth: "552px" },
              item: (
                <div className="about-us-text-box">
                  {aboutUsSingleton &&
                    aboutUsSingleton.goalsContent !== null &&
                    aboutUsSingleton.goalsContent !== undefined &&
                    ReactHtmlParser(aboutUsSingleton.goalsContent)}
                </div>
              ),
            },
            {
              header: "Zespół",
              isHidden: aboutUsSingleton
                ? aboutUsSingleton?.isTeamHidden
                : true,
              wysiwygable: true,
              headerInContent: "Zespół",
              headerInContentStyle: {
                maxWidth: "563px",
                padding: "0 45px",
              },
              textBoxStyle: {
                paddingLeft: 0,
                paddingRight: 0,
                paddingBottom: 0,
              },
              displayLineInLeft: true,
              item: (
                <div className="team" ref={this.teamRef}>
                  <ScrollableList
                    items={teamMembers.map((member, i) => (
                      <Tile
                        key={member.name}
                        title={member.name}
                        description={member.description}
                        imagePath={member.photo?.path}
                        style={{ flex: 1 }}
                      />
                    ))}
                    maxDisplayedItems={4}
                  />
                </div>
              ),
            },
          ]}
        />
      </div>
    );
  }
}

export default AboutUs;

import React from "react";
import { connect } from "react-redux";
import { AppState, AppDispatch } from "../../../core/store";
import {
  AnimationableLinkStateProps,
  AnimationableLinkDispatchProps,
  AnimationableLinkProps,
  AnimationableLinkState,
} from "./AnimationableLink.types";
import "./AnimationableLink.style.scss";
import { withRouter } from "react-router";
import { motion } from "framer-motion";

const mapStateToProps = (state: AppState): AnimationableLinkStateProps => ({});

const mapDispatchToProps = (
  dispatch: AppDispatch
): AnimationableLinkDispatchProps => ({});

const connector = connect(mapStateToProps, mapDispatchToProps);

class AnimationableLink extends React.Component<
  AnimationableLinkProps,
  AnimationableLinkState
> {
  public state: AnimationableLinkState = {};
  public constructor(props: AnimationableLinkProps) {
    super(props);
  }

  public componentDidMount() {}

  public componentDidUpdate(
    oldProps: AnimationableLinkProps,
    oldState: AnimationableLinkState
  ) {}

  public handleTap = () => {
    const { history, to, disabled, onClick } = this.props;
    if (disabled) return;
    history.push(to);
    onClick && onClick();
  };

  public render(): JSX.Element {
    const { children, className, disabled } = this.props;
    const {} = this.state;

    return (
      <motion.button
        onTap={this.handleTap}
        whileTap={{ scale: 0.9 }}
        className={`animationablelink ${className}`}
        disabled={disabled}
      >
        {children}
      </motion.button>
    );
  }
}

export default withRouter(AnimationableLink);

import React from "react";
import { connect } from "react-redux";
import { AppState, AppDispatch } from "../../../core/store";
import {
  CookiesWindowStateProps,
  CookiesWindowDispatchProps,
  CookiesWindowProps,
  CookiesWindowState,
} from "./CookiesWindow.types";
import "./CookiesWindow.style.scss";
import { getStorage, setStorage } from "../../../core/utils";

const mapStateToProps = (state: AppState): CookiesWindowStateProps => ({});

const mapDispatchToProps = (
  dispatch: AppDispatch
): CookiesWindowDispatchProps => ({});

const connector = connect(mapStateToProps, mapDispatchToProps);

class CookiesWindow extends React.Component<
  CookiesWindowProps,
  CookiesWindowState
> {
  public state: CookiesWindowState = {
    isCookiesAccepted: false,
  };
  public constructor(props: CookiesWindowProps) {
    super(props);
  }

  public componentDidMount() {
    const ls = getStorage();
    ls && this.setState({ isCookiesAccepted: Boolean(ls.isCookiesAccepted) });
  }

  public componentDidUpdate(
    oldProps: CookiesWindowProps,
    oldState: CookiesWindowState
  ) {}

  public handleCookieAcceptButtonClick = () => {
    setStorage({ isCookiesAccepted: true });
    this.setState({ isCookiesAccepted: true });
  };

  public handlePrivacyPolicyButtonClick = () => {};

  public render(): JSX.Element {
    const {} = this.props;
    const { isCookiesAccepted } = this.state;

    return (
      <>
        {!isCookiesAccepted && (
          <div className={"cookieswindow"}>
            <div className="cookie-box">
              <span>
                Ta strona używa plików cookies. Korzystając z aplikacji
                automatycznie akceptujesz politykę prywatności.
              </span>
              <div className="buttons">
                <button
                  className="privacy-policy"
                  onClick={this.handlePrivacyPolicyButtonClick}
                >
                  Polityka prywatności
                </button>
                <button
                  className="ok"
                  onClick={this.handleCookieAcceptButtonClick}
                >
                  OK
                </button>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default CookiesWindow;

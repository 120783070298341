import React from "react";
import { connect } from "react-redux";
import { AppState, AppDispatch } from "../../../core/store";
import {
  HotNewsStateProps,
  HotNewsDispatchProps,
  HotNewsProps,
  HotNewsState,
} from "./HotNews.types";
import "./HotNews.style.scss";
import BlogPreview from "../BlogPreview/BlogPreview";
import blogsMock from "../LastBlogs/blogs.mock.json";
import { fetchFromApi, getImageUrl } from "../../../core/utils";

const mapStateToProps = (state: AppState): HotNewsStateProps => ({});

const mapDispatchToProps = (
  dispatch: AppDispatch
): HotNewsDispatchProps => ({});

const connector = connect(mapStateToProps, mapDispatchToProps);

class HotNews extends React.Component<HotNewsProps, HotNewsState> {
  public state: HotNewsState = {
    blogs: [],
  };
  public constructor(props: HotNewsProps) {
    super(props);
  }

  public componentDidMount() {
    this.fetchRecommendedBlogs();
  }

  public componentDidUpdate(oldProps: HotNewsProps, oldState: HotNewsState) {}

  public fetchRecommendedBlogs = async () => {
    const blogs = await fetchFromApi<Blog, "collections">(
      "collections",
      "blogs",
      undefined,
      {
        filter: {
          isRecommended: true,
        },
        sort: { _created: -1 },
        limit: 4,
      }
    );
    this.setState({ blogs });
  };

  public render(): JSX.Element {
    const {} = this.props;
    const { blogs } = this.state;

    return (
      <div className={"hotnews"}>
        <div className="left-side">
          <div
            className="main-news news"
            style={{
              backgroundImage: `url(${getImageUrl(blogs[0]?.image)})`,
            }}
          >
            <div className="shadow-box">
              <BlogPreview
                className="hot-news-blog-preview"
                blog={blogs[0]}
                hideImage={true}
              />
            </div>
          </div>
        </div>
        <div className="right-side">
          <div className="background"></div>
          <div className="previews">
            {blogs.slice(1, 4).map((blog) => (
              <div key={blog._id} className="preview-wrapper">
                <BlogPreview className="hot-news-blog-preview" blog={blog} />
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default HotNews;

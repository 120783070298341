import React from "react";
import { connect } from "react-redux";
import { AppState, AppDispatch } from "../../../core/store";
import {
  FooterStateProps,
  FooterDispatchProps,
  FooterProps,
  FooterState,
} from "./Footer.types";
import "./Footer.style.scss";
import { Link } from "react-router-dom";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaPinterestP,
  FaPlus,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";
import { IconType } from "react-icons";
import {
  fetchFromApi,
  getImageUrl,
  getStorage,
  isValidate,
  JoiEmailAddress,
  setStorage,
} from "../../../core/utils";

import menuOptions from "../Menu/menu-options.json";
import { url } from "inspector";

const mapStateToProps = (state: AppState): FooterStateProps => ({});

const mapDispatchToProps = (dispatch: AppDispatch): FooterDispatchProps => ({});

const connector = connect(mapStateToProps, mapDispatchToProps);

class Footer extends React.Component<FooterProps, FooterState> {
  public state: FooterState = {
    emailAddress: "",
    lastNewsletterAdded: undefined,
    tabs: [],
  };
  public constructor(props: FooterProps) {
    super(props);
  }

  public componentDidMount() {
    this.fetchTabs();
    this.load();
  }

  public componentDidUpdate(oldProps: FooterProps, oldState: FooterState) {}

  public fetchTabs = async () => {
    const tabs = await fetchFromApi<Tab, "collections">("collections", "tabs");
    this.setState({ tabs });
  };

  public load() {
    const ls = getStorage();
    ls &&
      this.setState({
        emailAddress: ls.emailAddress || "",
        lastNewsletterAdded: ls.lastNewsletterAdded,
      });
  }

  public getSocialMediaComponent = (kind: SocialMediaKind) => {
    const Components: {
      [key in SocialMediaKind]: IconType;
    } = {
      facebook: FaFacebookF,
      twitter: FaTwitter,
      instagram: FaInstagram,
      youtube: FaYoutube,
      linkedin: FaLinkedinIn,
      pinterest: FaPinterestP,
    };

    const Component = Components[kind];
    return <Component size={14} color={"#555"} />;
  };

  public handleNewsletterInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value: emailAddress } = event.target;
    this.setState({ emailAddress });
  };

  public isFormValidate = () => {
    const { emailAddress } = this.state;
    return isValidate(JoiEmailAddress, emailAddress);
  };

  public handleAddEmailClick = async () => {
    const { emailAddress } = this.state;
    const newsletter = await fetchFromApi<NewsletterForm, "forms">(
      "forms",
      "newsletter",
      {
        emailAddress,
      }
    );
    const lastNewsletterAdded = Date.now();
    this.setState({ lastNewsletterAdded });
    setStorage({ emailAddress, lastNewsletterAdded });
  };

  public render(): JSX.Element {
    const { socialMedias, footerSingleton, sections } = this.props;
    const { emailAddress, lastNewsletterAdded, tabs } = this.state;

    return (
      <div className={"footer"}>
        <div className="green-map-wrapper">
          <div className="green-map"></div>
        </div>
        <div className="inner">
          <div className="footer-top">
            <section className="footer-menu">
              <h4>
                <div
                  className="icon"
                  style={{
                    backgroundImage: footerSingleton
                      ? `url(${getImageUrl(footerSingleton.icon)})`
                      : undefined,
                  }}
                ></div>
                {footerSingleton?.title}
              </h4>
              <div className="line"></div>
              <ul>
                {tabs.map((tab) => (
                  <li key={tab.name}>
                    <Link to={tab.to}>{tab.name}</Link>
                  </li>
                ))}
              </ul>
            </section>
            <section className="footer-contact">
              <h4>Kontakt z nami</h4>
              {footerSingleton?.phoneNumber && (
                <>
                  <div className="line"></div>
                  <h3 className="phone-number">
                    {footerSingleton?.phoneNumber}
                  </h3>
                </>
              )}
              {footerSingleton?.emailAddress && (
                <>
                  <div className="line"></div>
                  <span>{footerSingleton?.emailAddress}</span>
                </>
              )}

              <div className="line"></div>
              <div>
                <span>{document.location.hostname}</span>
              </div>
              <div>
                <span>{footerSingleton?.street}</span>
              </div>
              <div>
                <span>{footerSingleton?.city}</span>
              </div>
              {footerSingleton?.institutionName && (
                <div>
                  <span>{footerSingleton.institutionName}</span>
                </div>
              )}
              {footerSingleton?.krs && (
                <div>
                  <span>{footerSingleton.krs}</span>
                </div>
              )}
              {footerSingleton?.nip && (
                <div>
                  <span>{footerSingleton.nip}</span>
                </div>
              )}
            </section>
            {footerSingleton !== undefined &&
              !footerSingleton.isNewsletterHidden && (
                <section className="footer-newsletter">
                  <h4>Newsletter</h4>
                  <div className="line"></div>
                  <div className="footer-newsletter-inner">
                    <div className="newsletter-input-wrapper">
                      <input
                        className="newsletter-input"
                        placeholder="Wpisz swój adres e-mail"
                        onChange={this.handleNewsletterInputChange}
                        value={emailAddress}
                        disabled={Boolean(lastNewsletterAdded)}
                      />
                      <button
                        className="add-email"
                        onClick={this.handleAddEmailClick}
                        disabled={
                          !this.isFormValidate() || Boolean(lastNewsletterAdded)
                        }
                      >
                        <FaPlus />
                      </button>
                    </div>
                    <div className="line"></div>
                    <div className="social-media">
                      {socialMedias.map((socialMedia, i) => (
                        <a
                          href={socialMedia.url}
                          key={socialMedia.url}
                          target="_blank"
                          rel="noreferrer"
                          className="square"
                        >
                          {this.getSocialMediaComponent(socialMedia.kind)}
                        </a>
                      ))}
                    </div>
                  </div>
                </section>
              )}
          </div>
          <div className="footer-bottom">
            <section className="copyright-bottom">
              <div className="line"></div>
              <div className="footer-bottom-content">
                <span>
                  Copyright ©{new Date().getFullYear()}{" "}
                  {footerSingleton?.copyright}
                </span>
              </div>
            </section>
            <section className="shareholders-bottom">
              <div className="line long"></div>
              <div className="footer-bottom-content">
                <Link className="shareholder" to={"/partnerzy"}>
                  Organizator
                </Link>

                <Link className="shareholder" to={"/partnerzy"}>
                  Partnerzy
                </Link>
                <Link className="shareholder" to={"/partnerzy"}>
                  Patroni
                </Link>
              </div>
            </section>
            <section className="empty-bottom">
              <div className="line long"></div>
            </section>
            <section className="project-bottom">
              <div className="line long"></div>
              <div className="footer-bottom-content">
                <span>Projekt lodowski.art</span>
              </div>
            </section>
          </div>
          <div className="additional-bottom">
            <div
              className="banner"
              style={{
                backgroundImage: `url(${getImageUrl(footerSingleton?.banner)}`,
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;

import React from "react";
import { connect } from "react-redux";
import { AppState, AppDispatch } from "../../../core/store";
import {
  HomeStateProps,
  HomeDispatchProps,
  HomeProps,
  HomeState,
} from "./Home.types";
import "./Home.style.scss";
import { fetchFromApi } from "../../../core/utils";
import HotNews from "../../entities/HotNews/HotNews";
import LastBlogs from "../../entities/LastBlogs/LastBlogs";

const mapStateToProps = (state: AppState): HomeStateProps => ({});

const mapDispatchToProps = (dispatch: AppDispatch): HomeDispatchProps => ({});

const connector = connect(mapStateToProps, mapDispatchToProps);

class Home extends React.Component<HomeProps, HomeState> {
  public state: HomeState = {};
  public constructor(props: HomeProps) {
    super(props);
  }

  public componentDidMount() {}

  public componentDidUpdate(oldProps: HomeProps, oldState: HomeState) {}

  public render(): JSX.Element {
    const {} = this.props;
    const {} = this.state;

    return (
      <div className={"home"}>
        <HotNews />
        <LastBlogs />
      </div>
    );
  }
}

export default Home;

import React from "react";
import { connect } from "react-redux";
import { AppState, AppDispatch } from "../../../core/store";
import {
  PageContentStateProps,
  PageContentDispatchProps,
  PageContentProps,
  PageContentState,
} from "./PageContent.types";
import "./PageContent.style.scss";
import Home from "../../pages/Home/Home";
import { Route, Switch } from "react-router";
import Contact from "../../pages/Contact/Contact";
import AboutUs from "../../pages/AboutUs/AboutUs";
import { AnimatedSwitch, spring } from "react-router-transition";
import Blogs from "../../pages/Blogs/Blogs";
import Blog from "../../pages/Blog/Blog";
import Partners from "../../pages/Partners/Partners";

const mapStateToProps = (state: AppState): PageContentStateProps => ({});

const mapDispatchToProps = (
  dispatch: AppDispatch
): PageContentDispatchProps => ({});

const connector = connect(mapStateToProps, mapDispatchToProps);

function bounce(val: number) {
  return spring(val, {
    stiffness: 330,
    damping: 22,
  });
}

const bounceTransition = {
  // start in a transparent, upscaled state
  atEnter: {
    opacity: 0,
    scale: 1.2,
  },
  // leave in a transparent, downscaled state
  atLeave: {
    opacity: bounce(0),
    scale: bounce(0.8),
  },
  // and rest at an opaque, normally-scaled state
  atActive: {
    opacity: bounce(1),
    scale: bounce(1),
  },
};

function mapStyles(styles: React.CSSProperties) {
  return {
    opacity: styles.opacity,
    transform: `translateX(${styles.translate})`,
  };
}

class PageContent extends React.Component<PageContentProps, PageContentState> {
  public state: PageContentState = {};
  public constructor(props: PageContentProps) {
    super(props);
  }

  public componentDidMount() {}

  public componentDidUpdate(
    oldProps: PageContentProps,
    oldState: PageContentState
  ) {}

  public render(): JSX.Element {
    const {} = this.props;
    const {} = this.state;

    return (
      <div className={"pagecontent"}>
        <AnimatedSwitch
          atEnter={bounceTransition.atEnter}
          atLeave={bounceTransition.atLeave}
          atActive={bounceTransition.atActive}
          mapStyles={mapStyles}
          className="switch-wrapper"
        >
          <Route path="/" exact={true}>
            <Home />
          </Route>
          <Route path="/blog/:blogCreated/:blogEncodedTitle">
            <Blog />
          </Route>
          <Route path="/blog">
            <Blogs />
          </Route>

          <Route path="/kontakt">
            <Contact />
          </Route>
          <Route path="/o-nas">
            <AboutUs />
          </Route>
          <Route path="/partnerzy">
            <Partners />
          </Route>
        </AnimatedSwitch>
      </div>
    );
  }
}

export default PageContent;

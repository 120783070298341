import React from "react";
import { connect } from "react-redux";
import { AppState, AppDispatch } from "../../../core/store";
import {
  BadgeStateProps,
  BadgeDispatchProps,
  BadgeProps,
  BadgeState,
} from "./Badge.types";
import "./Badge.style.scss";

const mapStateToProps = (state: AppState): BadgeStateProps => ({});

const mapDispatchToProps = (dispatch: AppDispatch): BadgeDispatchProps => ({});

const connector = connect(mapStateToProps, mapDispatchToProps);

class Badge extends React.Component<BadgeProps, BadgeState> {
  public state: BadgeState = {};
  public constructor(props: BadgeProps) {
    super(props);
  }

  public componentDidMount() {}

  public componentDidUpdate(oldProps: BadgeProps, oldState: BadgeState) {}

  public handleClick = () => {
    const { onClick, disabled } = this.props;
    if (disabled) return;
    onClick && onClick();
  };

  public render(): JSX.Element {
    const { children, onClick, className, noFrame, disabled } = this.props;
    const {} = this.state;

    return (
      <div
        className={`badge-wrapper ${className} ${disabled ? "disabled" : ""} ${
          noFrame ? "no-frame" : ""
        }`}
        style={{ cursor: onClick ? "pointer" : undefined }}
        onClick={onClick}
      >
        <div className={"badge"}>
          <div className="inner">
            <div className="half-square top-left"></div>
            <div className="half-square top-right"></div>
            <div className="half-square bottom-left"></div>
            <div className="half-square bottom-right"></div>
            <span>{children}</span>
          </div>
        </div>
      </div>
    );
  }
}

export default Badge;

import React from "react";
import { connect } from "react-redux";
import { AppState, AppDispatch } from "../../../core/store";
import {
  SearchStateProps,
  SearchDispatchProps,
  SearchProps,
  SearchState,
} from "./Search.types";
import "./Search.style.scss";
import {
  encodeBlogUrl,
  fetchFromApi,
  getDateString,
  getImageUrl,
  sleep,
} from "../../../core/utils";
import OutsideClickHandler from "react-outside-click-handler";
import Badge from "../../shared/Badge/Badge";
import AnimationableLink from "../../shared/AnimationableLink/AnimationableLink";
import { ImSpinner2 } from "react-icons/im";
import { FaSearch } from "react-icons/fa";

const mapStateToProps = (state: AppState): SearchStateProps => ({});

const mapDispatchToProps = (dispatch: AppDispatch): SearchDispatchProps => ({});

const connector = connect(mapStateToProps, mapDispatchToProps);

class Search extends React.Component<SearchProps, SearchState> {
  public state: SearchState = {
    searchInputValue: "",
    isPending: false,
    blogsResult: [],
  };
  public constructor(props: SearchProps) {
    super(props);
  }

  public componentDidMount() {}

  public componentDidUpdate(oldProps: SearchProps, oldState: SearchState) {}

  public fetchBlogs = async (searchInputValue: string) => {
    const { isPending } = this.state;
    if (isPending) return;
    this.setState({ isPending: true, blogsResult: [] });
    await sleep(1500);
    const blogsResult = await fetchFromApi<
      {
        _created: number;
        sectionName: SectionName;
        title: string;
        image: Image;
        tags: string[] | null;
      },
      "collections"
    >("collections", "blogs", undefined, {
      filter: {
        $or: [
          { title: { $regex: `${searchInputValue}` } },
          { tags: { $has: `${searchInputValue}` } },
        ],
      },
      limit: 10,
      fields: { _created: true, sectionName: true, title: true, image: true },
      sort: { _created: -1 },
    });
    this.setState({ isPending: false, blogsResult });
  };

  public handleSearchInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value: searchInputValue } = event.target;
    this.setState({ searchInputValue });
  };

  public handleSearchInputKeyUp = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    const { key } = event;
    const { isPending } = this.state;
    if (key !== "Enter") return;
    if (isPending) return;
    const { searchInputValue } = this.state;
    this.fetchBlogs(searchInputValue);
  };

  public handleSearchOutsideClick = () => {
    this.setState({ blogsResult: [] });
  };

  public handleResultClick = () => {
    this.setState({ blogsResult: [] });
  };

  public render(): JSX.Element {
    const {} = this.props;
    const { blogsResult, isPending } = this.state;

    return (
      <div className="Search">
        <OutsideClickHandler onOutsideClick={this.handleSearchOutsideClick}>
          <div className="search-input-wrapper">
            {isPending && (
              <ImSpinner2 className="spinner" color={"#999"} size={8} />
            )}
            {!isPending && (
              <FaSearch className="search-icon" color={"#999"} size={8} />
            )}
            <input
              className="search-input"
              type="text"
              placeholder={"Szukaj treści w serwisie...".toUpperCase()}
              onChange={this.handleSearchInputChange}
              onKeyUp={this.handleSearchInputKeyUp}
            />
          </div>
          {Boolean(blogsResult.length) && (
            <div className="search-results">
              {blogsResult.map((result, i) => (
                <AnimationableLink
                  className="result"
                  to={`/blog/${result._created}/${encodeBlogUrl(result.title)}`}
                  key={result._id}
                  onClick={this.handleResultClick}
                >
                  <div
                    className="result-image"
                    style={{
                      backgroundImage: `url(${getImageUrl(result.image)})`,
                    }}
                  ></div>
                  <div className="result-content">
                    <Badge>{result.sectionName.display}</Badge>
                    <span className="title">{result.title}</span>
                  </div>
                </AnimationableLink>
              ))}
            </div>
          )}
        </OutsideClickHandler>
      </div>
    );
  }
}

export default Search;

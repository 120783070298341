import React from "react";
import { connect } from "react-redux";
import { AppState, AppDispatch } from "../../../core/store";
import {
  PartnersStateProps,
  PartnersDispatchProps,
  PartnersProps,
  PartnersState,
} from "./Partners.types";
import "./Partners.style.scss";

import logos from "./logos.mock.json";
import { getImageUrl } from "../../../core/utils";

const mapStateToProps = (state: AppState): PartnersStateProps => ({});

const mapDispatchToProps = (
  dispatch: AppDispatch
): PartnersDispatchProps => ({});

const connector = connect(mapStateToProps, mapDispatchToProps);

class Partners extends React.Component<PartnersProps, PartnersState> {
  public state: PartnersState = {};
  public constructor(props: PartnersProps) {
    super(props);
  }

  public componentDidMount() {}

  public componentDidUpdate(oldProps: PartnersProps, oldState: PartnersState) {}

  public render(): JSX.Element {
    const { shareholders } = this.props;
    const {} = this.state;

    const organizers = shareholders.filter((sh) => sh.kind === "organizer");
    const partners = shareholders.filter((sh) => sh.kind === "partner");
    const sponsors = shareholders.filter((sh) => sh.kind === "sponsor");

    return (
      <div className={"Partners"}>
        <div className="partners-content">
          <section className="organizers">
            <h4>Organizator</h4>
            <div className="rects">
              {organizers.map((organizer, i) => (
                <a
                  href={organizer.url}
                  target="_blank"
                  className="rect-wrapper"
                  key={organizer.name}
                >
                  <div className="rect">
                    <div
                      className="logo"
                      title={organizer.name}
                      style={{
                        backgroundImage: `url(${getImageUrl(organizer.image)})`,
                      }}
                    ></div>
                  </div>
                </a>
              ))}
            </div>
          </section>
          <section className="partners">
            <h4>Partnerzy</h4>
            <div className="rects">
              {partners.map((organizer, i) => (
                <a
                  href={organizer.url}
                  target="_blank"
                  className="rect-wrapper"
                  key={organizer.name}
                >
                  <div className="rect" key={i}>
                    <div
                      className="logo"
                      title={organizer.name}
                      style={{
                        backgroundImage: `url(${organizer.image.path})`,
                      }}
                    ></div>
                  </div>
                </a>
              ))}
            </div>
          </section>
          <section className="sponsors">
            <h4>Patroni</h4>
            <div className="rects">
              {sponsors.map((organizer, i) => (
                <a
                  href={organizer.url}
                  target="_blank"
                  className="rect-wrapper"
                  key={organizer.name}
                >
                  <div className="rect" key={i}>
                    <div
                      className="logo"
                      title={organizer.name}
                      style={{
                        backgroundImage: `url(${getImageUrl(organizer.image)})`,
                      }}
                    ></div>
                  </div>
                </a>
              ))}
            </div>
          </section>
        </div>
        <div className="decoration"></div>
      </div>
    );
  }
}

export default Partners;

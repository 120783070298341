import React from "react";
import { connect } from "react-redux";
import { AppState, AppDispatch } from "../../../core/store";
import {
  MenuStateProps,
  MenuDispatchProps,
  MenuProps,
  MenuState,
} from "./Menu.types";
import "./Menu.style.scss";

import { Link, NavLink } from "react-router-dom";
import SocialMedia from "../SocialMedia/SocialMedia";
import Search from "../Search/Search";
import _menuOptions from "./menu-options.json";
import { fetchFromApi, getImageUrl } from "../../../core/utils";

const mapStateToProps = (state: AppState): MenuStateProps => ({});

const mapDispatchToProps = (dispatch: AppDispatch): MenuDispatchProps => ({});

const connector = connect(mapStateToProps, mapDispatchToProps);

type MenuOption = {
  name: string;
  to: string;
  exact: boolean;
  hideInMenu?: boolean;
};

class Menu extends React.Component<MenuProps, MenuState> {
  public state: MenuState = {
    more: false,
    tabs: [],
  };
  public constructor(props: MenuProps) {
    super(props);
  }

  public componentDidMount() {
    this.fetchTabs();
  }

  public componentDidUpdate(oldProps: MenuProps, oldState: MenuState) {}

  public handleMoreOptionClick = () => {
    const { more } = this.state;
    this.setState({ more: !more });
  };

  public fetchTabs = async () => {
    const tabs = await fetchFromApi<Tab, "collections">("collections", "tabs");
    this.setState({ tabs });
  };
  public render(): JSX.Element {
    const { socialMedias, isFixed, basicSingleton } = this.props;
    const { more, tabs } = this.state;

    return (
      <div className={`menu ${isFixed ? "fixed" : ""}`}>
        <div className="menu-inner">
          <div className="options">
            {tabs.map((tab) => (
              <NavLink
                key={tab.name}
                to={tab.to}
                exact={tab.exact}
                className="menu-option basic"
                activeClassName="selected"
              >
                {tab.name}
              </NavLink>
            ))}
            <div
              className={`menu-option more ${more ? "selected" : ""}`}
              onClick={this.handleMoreOptionClick}
            >
              <div className="menu-select-label">
                <span>Więcej</span>
              </div>
              {more && (
                <div className={`menu-select`}>
                  {tabs.map((tab) => (
                    <NavLink
                      key={tab.name}
                      to={tab.to}
                      exact={tab.exact}
                      className="menu-option menu-select-option"
                      activeClassName="selected"
                    >
                      {tab.name}
                    </NavLink>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div className="other">
            {basicSingleton?.icon && (
              <img
                src={getImageUrl(basicSingleton?.icon)}
                alt={"Logo"}
                className="mini-logo"
              />
            )}
            <Search />
            <SocialMedia socialMedias={socialMedias} />
          </div>
        </div>
      </div>
    );
  }
}

export default Menu;

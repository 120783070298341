import React from "react";
import { connect } from "react-redux";
import { AppState, AppDispatch } from "../../../core/store";
import {
  LastBlogsSetStateProps,
  LastBlogsSetDispatchProps,
  LastBlogsSetProps,
  LastBlogsSetState,
} from "./LastBlogsSet.types";
import "./LastBlogsSet.style.scss";
import BlogPreview from "../BlogPreview/BlogPreview";

const mapStateToProps = (state: AppState): LastBlogsSetStateProps => ({});

const mapDispatchToProps = (
  dispatch: AppDispatch
): LastBlogsSetDispatchProps => ({});

const connector = connect(mapStateToProps, mapDispatchToProps);

class LastBlogsSet extends React.Component<
  LastBlogsSetProps,
  LastBlogsSetState
> {
  public state: LastBlogsSetState = {};
  public constructor(props: LastBlogsSetProps) {
    super(props);
  }

  public componentDidMount() {}

  public componentDidUpdate(
    oldProps: LastBlogsSetProps,
    oldState: LastBlogsSetState
  ) {}

  public render(): JSX.Element {
    const { blogs, direction } = this.props;
    const {} = this.state;

    return (
      <div className={`lastBlogsSet ${direction}`}>
        {blogs.map((blog, idx) => (
          <div key={blog._id} className={`preview-wrapper c${idx + 1}`}>
            <BlogPreview blog={blog} />
          </div>
        ))}
      </div>
    );
  }
}

export default LastBlogsSet;

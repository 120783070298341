import React from "react";
import { connect } from "react-redux";
import { AppState, AppDispatch } from "../../../core/store";
import {
  ContentBoxStateProps,
  ContentBoxDispatchProps,
  ContentBoxProps,
  ContentBoxState,
} from "./ContentBox.types";
import "./ContentBox.style.scss";
import LeftHeader from "../LeftHeader/LeftHeader";
import ReactHtmlParser from "react-html-parser";
import Badge from "../Badge/Badge";
import { Link } from "react-router-dom";
import { getImageUrl } from "../../../core/utils";

const mapStateToProps = (state: AppState): ContentBoxStateProps => ({});

const mapDispatchToProps = (
  dispatch: AppDispatch
): ContentBoxDispatchProps => ({});

const connector = connect(mapStateToProps, mapDispatchToProps);

class ContentBox extends React.Component<ContentBoxProps, ContentBoxState> {
  public sliderInterval: NodeJS.Timeout | undefined;
  public state: ContentBoxState = {
    currentSlideIdx: 0,
  };
  public constructor(props: ContentBoxProps) {
    super(props);
  }

  public componentDidMount() {
    const { slides } = this.props;
    if (slides !== undefined) this.runSlider();
  }

  public componentDidUpdate(
    oldProps: ContentBoxProps,
    oldState: ContentBoxState
  ) {
    const { slides } = this.props;
    if (slides !== undefined) this.runSlider();
  }

  public componentWillUnmount() {
    const { sliderInterval } = this;
    sliderInterval && clearInterval(sliderInterval);
  }

  public runSlider = () => {
    const { sliderInterval } = this;
    sliderInterval && clearInterval(sliderInterval);
    this.sliderInterval = setInterval(() => {
      const { currentSlideIdx } = this.state;
      const { slides } = this.props;
      if (!slides) return sliderInterval && clearInterval(sliderInterval);
      this.setState({
        currentSlideIdx:
          currentSlideIdx + 1 < slides.length ? currentSlideIdx + 1 : 0,
      });
    }, 4500);
  };

  public render(): JSX.Element {
    const { imagePath, sections, bottomStyle, slides, hideBackButton, bottom } =
      this.props;
    const { currentSlideIdx } = this.state;

    return (
      <div className={"contentbox"}>
        <div className="margin"></div>
        {sections
          .filter((section) => !section.isHidden)
          .map((section, idx) => (
            <div
              className={`section ${section.wysiwygable && "wysiwygable"}`}
              key={section.header}
            >
              {section.outer && <div className="outer">{section.outer}</div>}

              <LeftHeader
                visibleSquare={idx === 0}
                visibleLine={section.displayLineInLeft}
              >
                {section.header}
              </LeftHeader>
              <div className="right-side">
                {idx === 0 && (
                  <div className="content-top-image image-wrapper">
                    <div className="white-shadow shadow"></div>
                    <div className="gray-shadow shadow"></div>
                    <div
                      className="image"
                      style={{
                        backgroundImage: `url(${
                          slides && slides.length && slides[currentSlideIdx]
                            ? getImageUrl(slides[currentSlideIdx]?.image)
                            : getImageUrl(
                                imagePath ? { path: imagePath } : undefined
                              )
                        })`,
                      }}
                    >
                      {slides && slides.length && slides[currentSlideIdx] && (
                        <div className="slide-content">
                          {slides[currentSlideIdx].content}
                          <div className="slides-rings">
                            {slides.map((slide, idx) => (
                              <div
                                key={slide.image.path}
                                className={`slide-ring ${
                                  idx === currentSlideIdx ? "current" : ""
                                }`}
                              >
                                <div className="slide-ring-inner"></div>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                <div
                  className={`text-box ${idx === 0 && "cover-image"} ${
                    section.transparentBackground && "transparent"
                  }`}
                  ref={section.textBoxRef}
                  style={section.textBoxStyle}
                >
                  {section.headerInContent && (
                    <div
                      className="header-in-content"
                      style={section.headerInContentStyle}
                    >
                      <h2>{section.headerInContent}</h2>
                    </div>
                  )}
                  {typeof section.item === "string"
                    ? ReactHtmlParser(section.item)
                    : section.item}
                  {section.bottomItem}
                  {!hideBackButton && idx === 0 && (
                    <Link to={"/"} className="back">
                      <Badge noFrame={true} className="back-badge">
                        Powrót
                      </Badge>
                    </Link>
                  )}
                </div>
              </div>
            </div>
          ))}
        {bottom && (
          <div className="bottom" style={bottomStyle}>
            {bottom}
          </div>
        )}
      </div>
    );
  }
}

export default ContentBox;

import React from "react";
import { connect } from "react-redux";
import { AppState, AppDispatch } from "../../../core/store";
import {
  LeftHeaderStateProps,
  LeftHeaderDispatchProps,
  LeftHeaderProps,
  LeftHeaderState,
} from "./LeftHeader.types";
import "./LeftHeader.style.scss";

const mapStateToProps = (state: AppState): LeftHeaderStateProps => ({});

const mapDispatchToProps = (
  dispatch: AppDispatch
): LeftHeaderDispatchProps => ({});

const connector = connect(mapStateToProps, mapDispatchToProps);

class LeftHeader extends React.Component<LeftHeaderProps, LeftHeaderState> {
  public state: LeftHeaderState = {};
  public constructor(props: LeftHeaderProps) {
    super(props);
  }

  public componentDidMount() {}

  public componentDidUpdate(
    oldProps: LeftHeaderProps,
    oldState: LeftHeaderState
  ) {}

  public render(): JSX.Element {
    const { children, visibleSquare, visibleLine } = this.props;
    const {} = this.state;

    return (
      <div className={"leftheader"}>
        {visibleLine && (
          <div className="extended-line-wrapper">
            <div className="extended-line"></div>
          </div>
        )}
        {visibleSquare && (
          <>
            <div className="gray-square-box">
              <div className="gray-square"></div>
            </div>
            <h2>{children}</h2>
            <div className="line-box">
              <div className={`line`}>
                <div className="dotted"></div>
                <div className="inner"></div>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}

export default LeftHeader;
